<template>
	<div>

		<el-dialog class="cus_dialog" title="部门角色" width="1000px" :visible.sync="visible" :close-on-click-modal="false"
			@close="closeDialog">
			<div style="text-align:right;padding-bottom:10px">
			<span style="padding-right:10px;font-size:12px;color:orangered">变动后请在上级刷新页面</span>
				<el-button size="small" @click="formAdd" icon="el-icon-plus">添加部门角色</el-button>
			</div>
			<el-table height="100%" :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border
				stripe default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
				<el-table-column type="index" label="序号" width="60px"></el-table-column>
				<el-table-column prop="role_name" label="角色名称"></el-table-column>
				<el-table-column prop="role_desc" label="角色描述"></el-table-column>
				<el-table-column prop="role_desc" label="部门领导">
					<template slot-scope="scope">
						{{ scope.row.isleader ? '是' : '' }}
					</template>
				</el-table-column>
				<el-table-column prop="sort" width="80px" label="排序"></el-table-column>
				<el-table-column fixed="right" label="操作" width="200">
					<template slot-scope="scope">
						<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
							iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
							<a slot="reference" size="small"
								style="color: red;font-size: 12px;margin-right: 10px;">删除</a>
						</el-popconfirm>
						<el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>
					</template>
				</el-table-column>
			</el-table>


		</el-dialog>

		<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="formData" ref="ruleForm" label-width="120px" :rules="rules"
				style="max-height: 450px;overflow-y: auto;min-height: 300px;">
				<el-form-item label="部门" v-if="false">
					<el-cascader ref="firstCascader" :options="treeData" :props="firstCascaderProps"
						v-model="formData.dept_id" :show-all-levels="false" @change="handleCascaderChange"
						placeholder="请选择" style="width: 50%" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item label="角色名称" prop="role_name">
					<el-input v-model.trim="formData.role_name" style="width: 50%" />
				</el-form-item>
				<el-form-item label="角色描述" prop="role_desc">
					<el-input v-model.trim="formData.role_desc" style="width: 50%" />
				</el-form-item>
				<el-form-item label="是否部门领导" prop="role_desc">
					<el-checkbox v-model="formData.isleader">该角色是部门领导</el-checkbox>
				</el-form-item>
				<el-form-item label="排序号" prop="sort">
					<el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
						controls-position="right" />
				</el-form-item>



			</el-form>
			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
					<el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>
<script>
import api from '@/api/api';

export default {
	name: 'deptRole',
	data() {
		return {
			visible: true,
			isShowModal: false,
			dialogAuth: false,
			modalTitle: '添加',
			formData: {},
			firstCascaderProps: {
				label: 'dept_name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			//table
			tabMapOptions: [{
				label: '部门角色',
				key: 'CN'
			},],
			activeName: 'CN',
			tableData: [],
			rules: {
				role_name: [{
					required: true,
					message: '请输入角色名称',
					trigger: 'blur'
				}],
			},
			isShowMenu: false,
			menuTitle: '',
			menuIds: '',
			roleId: null,

			treeData: [],
			treeProps: {
				children: 'children',
				label: 'dept_name'
			},
			authData: [],
			activeItem: null,
			editDept: {},
		}
	},
	watch: {
		'formData.dept_id'() {
			if (this.$refs.firstCascader) {
				this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
			}
		}
	},
	mounted() {
		this.getList();

	},
	methods: {
		closeDialog() {
			this.$$parent(this, "showDeptroleManage", false)
		},

		getList() {
			this.$http.post(api.deptRoleList).then(res => {
				// for (let item of res.data.data) {
				// 	item.isleader = item.isleader ? true : false
				// }
				this.tableData = res.data.data
			})
		},
		formAdd() {
			this.isShowModal = true;
			this.formData = {
				sort: 1,
			}
			this.modalTitle = '添加'
		},
		handleModify(row) {
			this.isShowModal = true;
			this.modalTitle = '修改';
			this.formData = row
		},
		handleDel(row) {
			this.$http.post(api.deptRoleDel, {
				id: row.id
			}).then(res => {
				this.$message.success('删除成功')
				this.getList();
			})
		},
		handleCascaderChange() { },
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$http.post(api.deptRoleSave, this.formData).then(res => {
						this.$message.success('保存成功')
						this.getList();
						this.isShowModal = false;
						return
						if (res.code == 200) {
							this.$message.success(res.msg)
							this.getList();
							this.isShowModal = false;
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			})
		},
	}
}
</script>

<style scoped lang="less" type="text/less">
.DeptRole {
	height: 100%;

	.el-tabs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.el-tabs__content {
		flex: 1;

		.el-tab-pane {
			height: 100%;
		}
	}

	.treeContainer {
		height: 100%;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
		border: 1px solid rgba(220, 223, 230, 0.6);

		.treeTitle {
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #409eff;
			padding-left: 6px;
		}
	}
}
</style>
