<template>
	<div>
		<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" height="74vh" border stripe
			@selection-change="handleSelectionChange">
			<!-- <el-table-column type="selection" width="55">
			</el-table-column> -->
			<el-table-column prop="index" width="55" label="序号">
				<template slot-scope="scope">
					{{scope.$index+1+((Page.current_page-1)*Page.per_page)}}

				</template>
			</el-table-column>
			<!-- <el-table-column prop="username" label="头像" width="60">
				<template slot-scope="scope">
					<img :src="scope.row.avatar" v-if="scope.row.avatar"
						style="width: 32px; width: 32px; vertical-align: top;">
				</template>
			</el-table-column> -->
			<el-table-column prop="username" label="用户姓名">
				<template slot-scope="scope">
					<span v-if="scope.row.openid" style="color: #007AFF; cursor: pointer;"
						@click="unbindTeacher(scope.row)">{{scope.row.username}}</span>
					<span v-else >{{scope.row.username}}</span>
					<i class="el-icon-link" title="已绑定微信" v-if="scope.row.openid"></i>
				</template>
			</el-table-column>
			<el-table-column prop="loginname" label="登录名"></el-table-column>
			<el-table-column prop="phone" label="手机号" width="120"></el-table-column>
			
			<el-table-column prop="sort" label="排序" width="80">
				<template slot-scope="scope">
					<div>{{scope.row.sort}}</div>

				</template>
			</el-table-column>
			

			<el-table-column prop="index" min-width="100" label="角色">
				<template slot-scope="scope">
					<div v-if="scope.row.sys_roles&&scope.row.sys_roles.length>0" 
						style="cursor: pointer;">

						<el-tag size="mini" style="margin-right:5px" v-for="(d,idx) in scope.row.sys_roles" :key="idx">
							{{d.role_name}}</el-tag>

					</div>
					<div v-else @click="showOneDept(scope.row)" style="cursor: pointer;color: #67c23a">
						未设置
					</div>
				</template>
			</el-table-column>



			<!-- <el-table-column prop="index" width="100" label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.locked" style="color: orangered;">
                        已删除
                    </div>
                    <div v-else>正常</div>
                </template>
            </el-table-column>
-->

			<el-table-column fixed="right" label="操作" width="160">
				<template slot-scope="scope">
					<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
						iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
						<a slot="reference" size="small"
							style="color: red;font-size: 12px;margin-right: 10px; cursor: pointer;">删除</a>
					</el-popconfirm>
					<el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>

					<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
						iconColor="red" @confirm="resetPwd(scope.row)" placement="left" title="是否确定重置？">
						<a slot="reference" size="small"
							style="color: inherit;font-size: 12px;margin-left: 10px; cursor: pointer;">重置密码</a>
					</el-popconfirm>

					
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
			:current-page="Page.current_page" :page-size="Page.per_page" :page-sizes="[10,20, 50, 100, 200, 500, 1000]"
			layout="total, prev, pager, next,sizes, jumper" :total="Page.count">
		</el-pagination>

		<Tform :teacher_id="editrowid" v-if="editrowid">

		</Tform>
	</div>
</template>
<script>
import Tform from "./TeacherForm.vue"
export default {
	name: 'Table',
	components: { Tform },
	data() {
		return {
			editrowid: 0,
			Page: {}
		}
	},
	props: {
		page: {
			type: Object,
		},
		tableData: {
			type: Array,
			default: []
		},
		from: {
			type: String,
			default: ''
		},
		multSelect: {
			type: Boolean,
			default: true
		},
	},
	watch: {
		page: function (n, o) {
			this.Page = n

		}
	},
	mounted() {
		this.Page = this.page
	},
	methods: {
		handleModify(row) {
			this.editrowid = row.id
		},
		handleDel(row) {
			this.$emit('handleDel', row)
		},
		handleSelectionChange(val) {
			this.$emit('handleSelectionChange', val)
		},
		handlePageChange(e) {
			this.$$parent(this, "page").then(page => {

				page.current_page = e
				this.$$parent(this, "page", page)
				this.$$parent(this, "getTeachers")

			})

		},
		handleSizeChange(e) {

			this.$$parent(this, "page").then(page => {
				console.log(page)
				page.per_page = e
				this.$$parent(this, "page", page)
				this.$$parent(this, "getTeachers")

			})

		},
		showOneDept(e) {
			this.$$parent(this, "teacher_id", e.id)
			this.$$parent(this, "showTeacherInfo", true)
		},
		showOneTeacher(e) {
			this.$emit('showOneTeacher', e)
		},
		resetPwd(e) {
			this.$http.post("/api/reset_teache_pwd", {
				id: e.id
			}).then(res => {
				this.$message.success("密码已重置为手机号后六位")
			})

		},
		releaseDept(e) {
			this.$emit('releaseDept', e)

		},
		saveSort(row) {
			this.editrowid = 0;
			this.$http.post("/api/set_teacher_sort", {
				id: row.id,
				sort: row.sort
			}).then(res => {
				this.$message.success("修改成功")
			})
		},
		unbindTeacher(row) {
			this.$confirm('解绑后该微信登陆将失效，是否解绑?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/teacher_unbindwx", {
					id: row.id
				}).then(res => {
					this.$message.success("解绑成功")

					this.$$parent(this, "getTeachers")
				})
			}).catch(() => {

			});

		}

	}
}
</script>
